const navigation: any = []

const profileNavigation = [
  {
    label: 'Přehled',
    key: 'profile-overview',
    href: '/profile/overview',
    icon: 'i-fubex-square-menu',
    trkey: 'profileOverview',

  },
  {
    label: 'Směna',
    key: 'profile-exchange',
    href: '/profile/exchange',
    icon: 'i-fubex-exchange',
    trkey: 'profileExchange',

  },
  {
    label: 'Historie směn',
    key: 'profile-history',
    href: '/profile/history',
    icon: 'i-fubex-history',
    trkey: 'profileHistory',

  },
  {
    label: 'Můj účet',
    href: '/profile/account',
    key: 'profile',
    icon: 'i-fubex-settings',
    trkey: 'profile',

  },
  {
    label: 'Odhlásit se',
    key: 'logout',
    href: '/logout',
    icon: 'i-fubex-logout',
    trkey: 'logout',

  },
]
const mobileMenuOpen = ref(false)

export default function useNavigation () {
  const getNavigation = () => navigation
  return {
    mobileMenuOpen,
    navigation,
    profileNavigation,
    getNavigation,
  }
}
